import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { useAPI } from 'utils/api';
import { logRecommendAddCart } from 'utils/_omsLog';

import store, { actions, getters } from 'redux/store';
import IconCheck from 'images/icons/check.svg';

const SelectBtn = styled.div`
  background-color: #eeeeee;
  border: 1px solid #dddddd;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  position: absolute;
  right: 7px;
  bottom: 0;
  z-index: 100;

  &::before {
    content: '';
    visibility: hidden;
    background-image: url(${IconCheck});
    background-position: center;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  ${(props) =>
    props.active &&
    css`
      background-color: #5fd2da;

      &::before {
        visibility: visible;
      }
    `}
`;

const RecommendItem = ({ data, update, remove, inCart = false }) => {
  const api = useAPI();

  const currentStoreId = useSelector((state) => getters.getStoreId(state));
  const recommendData = useSelector((state) => getters.getRecommendData(state));

  const [updatePage, setUpdatePage] = useState(false);
  const [active, setActive] = useState(false);

  const deleteItem = () => {
    api
      .removeCart({
        productId: data.productId,
        currentStoreId: currentStoreId,
      })
      .then((res) => {
        if (!res) return;
        if (inCart && remove) {
          remove();
        }
        setActive(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateCart = (count) => {
    api
      .updateRecommendItems({
        modelCode: recommendData.modelCode,
        productId: data.productId,
        quantity: count,
        storeId: currentStoreId,
        itemOutofstockAction: 1,
        cartRecommend: true,
      })
      .then((res) => {
        if (update) {
          update();
        }
        setActive(true);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    setUpdatePage(true);
    // setQty(data.qty);
  }, []);

  useEffect(() => {
    return () => {
      setUpdatePage(false);
    };
  }, [updatePage]);

  return (
    <>
      <div
        className={
          inCart ? 'category-product-addition-item' : 'category-product-item'
        }
      >
        <div
          className="image"
          onClick={(e) => {
            e.stopPropagation();
            setActive(!active);
            if (active) {
              deleteItem();
            } else {
              logRecommendAddCart(recommendData, data);
              updateCart(1);
            }
          }}
        >
          {data.watermark &&
            data.watermark.coverVisible &&
            data.watermark.coverImageUrl && (
              <div className="watermark watermark-covert">
                <img
                  alt={data.brand ? data.brand : data.productName}
                  src={data.watermark.coverImageUrl}
                />
              </div>
            )}
          {data.watermark &&
            data.watermark.brandVisible &&
            data.watermark.brandImageUrl && (
              <div className="watermark watermark-lt watermark watermark-lt watermark-homepage watermark-menu">
                <img
                  alt={data.brand ? data.brand : data.productName}
                  src={data.watermark.brandImageUrl}
                />
              </div>
            )}
          {data.watermark &&
            data.watermark.discountVisible &&
            data.watermark.discountImageUrl && (
              <div className="watermark watermark-rt watermark-homepage watermark-menu">
                <img
                  alt={data.watermark.discountValue}
                  src={data.watermark.discountImageUrl}
                />
              </div>
            )}
          {data.watermark &&
            data.watermark.calloutMsgVisible &&
            data.watermark.calloutMsgImageUrl && (
              <div
                className={`watermark watermark-fullwidth watermark-lb ${
                  data.watermark.markVisible
                    ? 'watermark-menu-callout'
                    : 'watermark-menu-callout-full'
                }`}
              >
                <img
                  alt={data.watermark.productName}
                  src={data.watermark.calloutMsgImageUrl}
                />
              </div>
            )}
          {data.watermark &&
            data.watermark.markVisible &&
            data.watermark.markImageUrl && (
              <div className="watermark watermark-rb watermark-homepage watermark-menu">
                <img
                  alt={data.brand ? data.brand : data.productName}
                  src={data.watermark.markImageUrl}
                />
              </div>
            )}
          <img src={data.image_path} alt="" />
          <SelectBtn active={active} />
        </div>
        <div className="price-block">
          <div className="price">
            <span>$</span>
            {data.promo_price}
          </div>
          {data.promo_price !== data.price ? (
            <div className="origin-price">${data.price}</div>
          ) : undefined}
        </div>
        {data.productPromotions && data.productPromotions.length > 0 ? (
          data.productPromotions.map((item) => (
            <div>
              {item.calloutMsg != '' && (
                <div className="showDetail-block">
                  <div className="showtitle">
                    <span className="showcontent">{item.calloutMsg}</span>
                  </div>
                </div>
              )}
            </div>
          ))
        ) : (
          <></>
        )}
        <div className="title">{data.productName}</div>
      </div>

      <style jsx>{`
        .category-product-addition-item {
          width: auto;
          height: 100%;
          padding: 10px 5px;
          background: #ffffff;
          border: 1px solid #e3e3e3;
          box-sizing: border-box;
          border-radius: 8px;

          .image {
            position: relative;

            &::after {
              content: '';
              display: block;
              padding-bottom: 100%;
            }
            img {
              position: absolute;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }

            .amounter-box {
              position: absolute;
              bottom: 28px;
              left: 0;
              right: 0;
            }
          }
          .price-block {
            height: 23px;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            margin-top: 3px;
            .price {
              font-weight: bold;
              font-size: 16px;
              line-height: 23px;
              color: #3b3516;
              span {
                font-size: 12px;
                line-height: 17px;
                color: #3b3516;
              }
            }
            .origin-price {
              font-size: 12px;
              line-height: 17px;
              text-decoration-line: line-through;

              color: #999999;
            }
          }
          .title {
            margin-top: 3px;
            font-size: 12px;
            line-height: 17px;
            height: 34px;
            color: #3b3516;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          .showDetail-block {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            .showtitle {
              /* Frame 1 */
              box-sizing: border-box;
              /* Auto layout */
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              padding: 2px 6px;
              gap: 10px;
              justify-content: space-between;
              width: auto;
              height: 18px;
              left: 75px;
              top: 76px;

              background: #fdf8e4;
              border: 1px solid #f2ecd2;
              border-radius: 20px;
              .showcontent {
                width: 75px;
                height: 14px;
                font-family: 'Noto Sans TC';
                font-style: normal;
                font-weight: 400;
                font-size: 10px;
                line-height: 14px;
                color: #000000;
                /* Inside auto layout */
                flex: none;
                order: 0;
                flex-grow: 0;
                /* 除去多餘字元 */
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }
        }
        .category-product-item {
          width: calc((100vw - 48px) / 3);
          padding: 10px 5px;
          background: #ffffff;
          border: 1px solid #e3e3e3;
          box-sizing: border-box;
          border-radius: 8px;
          .image {
            position: relative;

            &::after {
              content: '';
              display: block;
              padding-bottom: 100%;
            }
            img {
              position: absolute;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }

            .amounter-box {
              position: absolute;
              bottom: 28px;
              left: 0;
              right: 0;
            }
          }
          .price-block {
            height: 23px;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            margin-top: 3px;
            .price {
              font-weight: bold;
              font-size: 16px;
              line-height: 23px;
              color: #3b3516;
              span {
                font-size: 12px;
                line-height: 17px;
                color: #3b3516;
              }
            }
            .origin-price {
              font-size: 12px;
              line-height: 17px;
              text-decoration-line: line-through;

              color: #999999;
            }
          }
          .title {
            margin-top: 3px;
            font-size: 12px;
            line-height: 17px;
            height: 34px;
            color: #3b3516;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          .showDetail-block {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            .showtitle {
              /* Frame 1 */
              box-sizing: border-box;
              /* Auto layout */
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              padding: 2px 6px;
              gap: 10px;
              justify-content: space-between;
              width: auto;
              height: 18px;
              left: 75px;
              top: 76px;

              background: #fdf8e4;
              border: 1px solid #f2ecd2;
              border-radius: 20px;
              .showcontent {
                width: 75px;
                height: 14px;
                font-family: 'Noto Sans TC';
                font-style: normal;
                font-weight: 400;
                font-size: 10px;
                line-height: 14px;
                color: #000000;
                /* Inside auto layout */
                flex: none;
                order: 0;
                flex-grow: 0;
                /* 除去多餘字元 */
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }
        }
      `}</style>
    </>
  );
};

export default RecommendItem;
